//公用的方法
import Vue from 'vue'
import { getBillNo } from '@/api/codeRule'

//input 显示时保留n位小数,四舍五入 输入时显示实际值
//引用类型 使用对象/数组
/* num 保留的小数位数
   type 类型 
   e     $event
   showData 显示的对象/数组
   savaData 实际的对象/数组
   value  属性名
   index 下标
    */
export function showFormatting(
  num,
  type,
  e,
  showData,
  savaData,
  value,
  index,
  newVal = undefined
) {
  if (type === 'blur') {
    //change事件
    if (e.target.value && !isNaN(e.target.value)) {
      Vue.set(savaData[index], value, parseFloat(e.target.value) || 0)
      Vue.set(showData[index], value, savaData[index][value].toFixed(num))
      if (showData[index][value] === '')
        Vue.set(saveData[index], value, showData[index][value])
    } else {
      Vue.set(savaData[index], value, undefined)
    }
  } else if (type === 'focus') {
    if (showData[index][value] === '') {
      Vue.set(savaData[index], value, showData[index][value])
      return
    }
    console.log('focus')
    if (savaData[index][value]) {
      Vue.set(showData[index], value, savaData[index][value].toString()) //focus事件
    }
  } else {
    if (newVal) {
      Vue.set(showData[index], newVal, Number(e).toFixed(num))
      Vue.set(savaData[index], newVal, Number(e))
      return
    }
    if (Object.keys(showData[index]).length) {
      Vue.set(showData[index], value, Number(savaData[index][value]).toFixed(num))
    } else {
      showData[index] = {}
      Vue.set(showData[index], value, Number(savaData[index][value]).toFixed(num))
    }

  }
}

//获取单据编号
export function getNewBillNo(obj, val, type) {
  return new Promise(resolve => {
    getBillNo(type).then(response => {
      Vue.set(obj, val, response)
      resolve(response)
    })
  })
}


/* 
    限制输入框
    整数位最大可输入intMax位
    小数位最大可输入dotMax位
    name：  绑定对象键值
    intMax：整数最大位数
    dotMax：小数最大位数
*/
export function inputLimit(num, intMax, dotMax, row, name) {
  //console.log(num, intMax, dotMax, row, name)
  var resNum = '';
  if(dotMax){
    num = ('' + num) // 第一步：转成字符串
    .replace(/[^\d^\.]+/g, '') // 第二步：把不是数字，不是小数点的过滤掉
    .replace(/^0+(\d)/, '$1') // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
    .replace(/^\./, '0.') // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
    .match(/^\d*(\.?\d{0,10})/g)[0] || '' // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，	而且小数点后面只能有0到2位小数
    /* 如果有小数 */
    if(num.indexOf('.') !== -1) {
      var arr = num.split('.')
      arr[0] = arr[0].length > intMax ? arr[0].substring(0, intMax) : arr[0]
      if(arr[1] !== '') {
          arr[1] = arr[1].length > dotMax ? arr[1].substring(0, dotMax) : arr[1]
          var result = arr.join('.')
          resNum = Number(result).toFixed(arr[1].length)
      }else{
          var result = arr.join('.')
          resNum =  result
      }
      Vue.set(row, name, resNum)
      return resNum
    }else{
        /* 如果没小数 一串数字，只需要限制位数*/
        resNum = num.length > intMax ? num.substring(0, intMax) : num
        Vue.set(row, name, resNum)
    }
  }else{
    var result = num.replace(/[^\d]/g, ''); //整数
    if(result.includes(".")){
      result = result.replace(".", "");
    }
    resNum = result.length > intMax ? result.substring(0, intMax) : result
    Vue.set(row, name, resNum)
  }
  return resNum
}