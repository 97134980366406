import {
  addBill,
  getDetailBill,
  updateBill,
  getSourceBill,
  getGoodsFinalQty
} from '@/api/purchase/index'
import { listGoods } from '@/api/goods/goods'

const { cloneDeep } = require('lodash')

let purchaseSet = ['110101', '110102', '110103', '140303']
let saleSet = ['120101', '140313', '120102', '120103']
let storeSet = [
  '107',
  '108',
  '130101',
  '130102',
  '130103',
  '130104',
  '130105',
  '130106',
  '130107',
  '130108',
  '000'
]

function isStoreModule(type) {
  return storeSet.includes(type)
}

function isPurchaseModule(type) {
  return purchaseSet.includes(type)
}
function isSaleModule(type) {
  return saleSet.includes(type)
}
const searchItem = {
  data() {
    return {
      detailFormat: false,
      sourceStatus: false
    }
  },
  watch: {
    'form.partnerName': function (val) {
      if (val && this.$route.query.type === 'Add') {
        // 先判断是否有引入源单数据
        if (
          this.form.details.length &&
          this.form.details.filter(item => item.sourceBillDetailId).length
        ) {
          this.form.details = [{}]
          this.$nextTick(() => {
            this.$refs.form.clearValidate()
          })
        }
      }
    }
  },
  methods: {
    selectProductController(data) { },
    stockCheckController(data) {
      //仓库盘点进入  获取商品库存数
      const goodsIds = data.map(v => v.goodsId) //获取id数组
      const obj = {
        goodsIds,
        storeId: this.form.storeId
      }

      getGoodsFinalQty(obj).then(response => {
        data = data.map(i => {
          response.data.goodsQtyList.forEach(j => {
            //遍历给每一项加上库存数
            if (i.goodsId === j.goodsId) {
              i.finalQty = j.finalQty //库存数赋值
              i.unitPrice = j.salePrice //单价字段转换赋值
              i.basUnitQty = 0 //初始化基本单位数量
            }
          })
          i.unitPrice = i.purPrice //商品价格字段转换
          i.remark = null
          return i
        })
        return data
      })
    },
    dataShowFilter(sourceAction) {
      let needReCalField = ['taxRate']
      let needFormatField = [
        'unitPrice',
        'unitTaxPrice',
        'unitTaxMoney',
        'attachMoney',
        'taxRate'
      ]
      //字段转换并保留n位小数
      for (let i = 0; i < this.form.details.length; i++) {
        for (let key in this.form.details[i]) {
          let price = this.unitPriceType // 区别单价取进价/售价 unitPriceType = purPrice 取进价  unitPriceType = salePrice 取售价
          if (key === price) {
            // 这里需要注意-> 引入源单回填价格需要取 unitPrice, 先不处理余下逻辑【23.10.8】
            if (sourceAction && sourceAction === 'source') {
              this.dataFormat(this.form.details[i], 'unitPrice', i)
            } else {
              this.dataReCalc(i, 'unitPrice', this.form.details[i][key])
            }

            // 2023.10.8先把bool判断去掉-这里应该只应用于采销
            if (
              isSaleModule(this.billType) ||
              isPurchaseModule(this.billType)
            ) {
              this.handleEdit(this.form.details[i], 'unitPrice', i) //计算单价/含税单价/税率/税额进入
            }
          } else if (needFormatField.includes(key)) {
            this.dataFormat(this.form.details[i], key, i)

            if (needReCalField.includes(key)) {
              // 2023.10.8先把bool判断去掉
              if (
                isSaleModule(this.billType) ||
                isPurchaseModule(this.billType)
              ) {
                this.handleEdit(this.form.details[i], 'taxRate', i) //计算单价/含税单价/税率/税额进入
              }
            }
          }
        }
      }
    },
    tableDataFilterEmpty() {
      this.form.details = this.form.details.filter(
        item =>
          (!(JSON.stringify(item) === '{}') && !(item.goodsId === undefined)) ||
          !(item.goodsId === undefined)
      )
    },
    // keyup (name, event, index, bool) {
    //   if (!bool && storeSet.includes(this.TypeBill)) {
    //     //需要检验是否选了仓库时进入
    //     if (!this.form.storeId) {
    //       this.$message({
    //         message: '请先选择仓库',
    //         type: 'warning'
    //       })
    //       return
    //     }
    //   }
    //   //判断是否输入编码或条码
    //   if (this.form.details[`${index}`][`${name}`]) {
    //     listGoods({
    //       [name]: this.form.details[`${index}`][`${name}`],
    //       partnerId: this.form.partnerId,
    //       isEnableSale: this.isEnableSale,
    //       isEnablePurchase: this.isEnablePurchase,
    //       searchType: this.searchType,
    //       queryCode: this.form.details[`${index}`][`${name}`]
    //     }).then(async response => {
    //       // 判断是否精准匹配 匹配直接赋值 不匹配弹出商品框
    //       if (response.total === 1) {
    //         event.target.blur()
    //         let target = response.rows[0]
    //         console.log(response.rows, '--输入的选择')
    //         if (!bool && this.TypeBill === '107') {
    //           target = this.stockCheckController(response.rows)[0]
    //         } else {
    //           //使用vue的$set方法更新数组数据,使视图可编辑
    //           // 2023.10.7，这里的方法目前来看貌似有点多余，主流程是 dataShowFilter, $set需要处理仓库相关，这里暂时不动
    //           // if (isPurchaseModule(this.TypeBill)) {
    //           //   target.unitPrice = target.purPrice
    //           // } else if (isSaleModule(this.TypeBill)) {
    //           //   target.unitPrice = target.salePrice
    //           // } else {
    //           //   // 仓库模块
    //           // }

    //           target.remark = null
    //         }
    //         this.$set(this.form.details, index, target)
    //         this.iskey = !this.iskey
    //         this.tableDataFilterEmpty()
    //         this.dataShowFilter()
    //       } else {
    //         this.queryCode = this.form.details[`${index}`][`${name}`]
    //         this.OpenGoods = true
    //       }
    //     })
    //   } else {
    //     //没输入直接弹出商品信息框
    //     event.target.blur()
    //     this.OpenGoods = true
    //   }
    // },
    async getGoods(data, bool) {
      // 在采销模块中，选择商品的备注不需要填入到表单中
      data.forEach(item => {
        item.remark = null
      })
      console.log(data, '选择商品的值')
      if (!bool && this.TypeBill === '107') {
        //需要检验是否选了仓库时进入
        if (!this.form.storeId) {
          this.$message({
            message: '请先选择仓库',
            type: 'warning'
          })
          return
        }
      }

      // this.tableDataFilterEmpty()
      if (!bool && this.TypeBill === '107') {
        data = this.stockCheckController(data)
      }
      data = data.map((item) => (
        {
          ...item,
          hoverRow: false
        }
      ))
      if (['140303', '140307', '140312'].includes(this.billType)) {
        data = data.map((item) => (
          {
            ...item,
            hoverRow: false,
            unitTaxPrice: Number(this.billType == '140303'? item.purPrice: item.salePrice) * (1 + (item.taxRate / 100 ))
          }
        ))
      }
      let findIndex = data[0].findIndex
      console.log(findIndex, 'findIndex')
      // return
      // if (['130102', '130103', '130104', '130105', '130106', '130107', '130108'].includes(this.billType) && findIndex!= undefined) {
      if (['110101', '110102', '110103', '120101', '140313', '120102', '120103', '140303', '140312'].includes(this.billType) && findIndex!= undefined && data.length == 1) {
        this.form.details[findIndex] = data[0]
      } else {
        this.form.details.push(...data) 
      }
      if (['110101', '110102', '110103', '120101', '140313', '120102', '120103', '140303', '140312'].includes(this.billType)) {
        this.form.details = this.form.details.filter(item => item.goodsNo != '' && item.goodsNo != undefined)
        
      }
      if (['140313'].includes(this.billType)) {
        this.form.details = this.form.details.map((item) => (
          {
            ...item,
            priceCaseName: this.priceCaseName,
            priceCaseId: this.priceCaseId,
          }
        ))
      }
      // this.form.details.push(...data) //追加到数组后面
      this.dataShowFilter()
    },
    //选中的商品-源单 数据
    async getSelectIds(data, bool) {
      if (!bool && this.TypeBill === '107') {
        //需要检验是否选了仓库时进入
        if (!this.form.storeId) {
          this.$message({
            message: '请先选择仓库',
            type: 'warning'
          })
          return
        }
      }
      this.tableDataFilterEmpty()
      if (this.TypeBill === '107') {
        //仓库盘点进入  获取商品库存数
        data = this.stockCheckController(response.rows)[0]
      }
      // 引入源单回填应收数量
      // data.forEach(item => {
      //   item.importUnitQty = item.unitQty
      // })
      console.log(data, '引入源单数据')
      data = data.map((item) =>(
        {
          ...item,
          unitQty: item.importUnitQty
        }
      ))
      if (['140303', '140307', '140312'].includes(this.billType)) {
        data = data.map((item) =>(
          {
            ...item,
            sourceBillType: item.billType
          }
        ))
      }
      if (['140303', '140312'].includes(this.billType)) {
        this.form.details = []
        this.form.details.push(...data) //追加到数组后面
      } else {
        this.form.details.push(...data) //追加到数组后面
      }
      // this.form.details.push(...data) //追加到数组后面

      //字段转换并保留n位小数才进入,这里与dataShowFilter的差异在于重置 showForm的值，暂不处理[23.10.7]  -23.10.8共用
      this.dataShowFilter('source')

      this.addActionRedo(data)
    },
    addActionRedo(data) {
      // 新建情况下 引入源单->回填仓库，采购员，部门字段2023.9.16
      if (!data.length) return
      console.log(data[0], '-引入源单')
      const type = this.$route.query.type
      if (type === 'Add') {
        const { storeName, storeId, employeeId, deptId } = data[0]
        const storeType = this.storePostType
        if (storeType === 'inStoreId') {
          this.form.inStoreName = storeName
          this.form.inStoreId = storeId
        } else if (storeType === 'outStoreId') {
          this.form.outStoreName = storeName
          this.form.outStoreId = storeId
        } else if (storeType === 'storeId') {
          this.form.storeName = storeName
          this.form.storeId = storeId
        }
        // 在采购，销售模块中，仓库是必填项，这里直接尝试获取
        this.setAddressInfo(storeId)

        if (employeeId) {
          if (this.BillSaleType) {
            this.form.purEmployeeId = employeeId
          } else {
            this.form.saleEmployeeId = employeeId
          }
        }
        if (deptId) {
          this.form.deptId = deptId
          this.$set(this.form, 'deptId', deptId)
        }

        this.form.storeAddress = {
          contactMan: data?.[0]?.consigneeName,
          contactTel: data?.[0]?.consigneeTel,
          regionId: data?.[0]?.consigneeRegionId,
          addressDetail: data?.[0]?.consigneeRegionAddress,
        }
        this.getAddress()

        this.$refs.form.clearValidate([
          'inStoreId',
          'outStoreId',
          'storeId',
          'saleEmployeeId',
          'purEmployeeId',
          'purDeptId'
        ])
      }
    },
    //根据单据id 获取单据详情
    getBillDetail(bool) {
      this.loading = true
      getDetailBill(this.billId, this.urlDetail).then(async response => {
        let temp = 1
        this.form = response.data
        this.deptIdProcess(this.form)
        console.log('csp - 采购明细 = ', this.form)
        if (bool) {
          //  需要收货地址跟字段转换保留n位小数才进入
          if (this.form.storeAddress === null) {
            this.form.storeAddress = {
              contactMan: undefined,
              contactTel: undefined,
              regionId: undefined,
              addressDetail: undefined
            }
          } else {
            this.getAddress()
          }
          this.detailDataFormat(cloneDeep(this.form.details))

          this.$nextTick(() => {
            this.billUpdateProcess()
          })
        }
        if (this.form.fileItems) {
          this.form.fileItems.forEach(i => {
            this.fileList.push({
              name: i.fileName,
              url: i.accessUrl,
              domain: i.domain,
              filePath: i.filePath,
              fileSize: i.fileSize,
              uid: new Date().getTime() + temp++
            })
          })
        }
        if (this.form.billStatus === '2') {
          this.disabled = true
          this.auditOrder = true
        } else if (this.form.billStatus === '4') {
          this.disabled = true
          this.stopOrder = true
          this.showAudit = true
        } else if (this.form.billStatus === '3') {
          this.disabled = true
          this.showAudit = true
          this.deleteOrder = true
        } else {
          this.disabled = false
          this.stopOrder = false
          this.showAudit = false
          this.auditOrder = false
          this.deleteOrder = false
          this.noAuditOrder = true
        }
        this.form.details = this.form.details.map((item) =>(
          {
            ...item,
            storeId: item.storeId? item.storeId : item.inStoreId? item.inStoreId : item.outStoreId,
          }
        ))
        if (this.form.fileItems?.length > 0) {
          this.form.fileItems = this.form.fileItems.map((item)=> (
            {
              ...item,
              name: item.fileName,
              url: item.accessUrl,
            }
          ))
        }
        this.loading = false
      })
    },
    detailDataFormat(data) {
      for (let i = 0; i < data.length; i++) {
        let obj = { ...data[i] }
        for (let key in obj) {
          if (
            key === 'unitPrice' ||
            key === 'unitTaxPrice' ||
            key === 'unitTaxMoney' ||
            key === 'attachMoney' ||
            key === 'taxRate'
          ) {
            this.dataFormat(obj, key, i)
          }
        }
      }
    },
    billUpdateProcess() {
      const sourceArr = this.form.details.filter(
        item => item.sourceBillDetailId
      )
      if (sourceArr.length) {
        this.sourceStatus = true
      }
    }
  }
}

const detailLayoutPublic = {
  data() {
    return {}
  },
  methods: {
    deptIdProcess(data) {
      /*
      前端统一部门字段回填
      在采购管理-销售管理中
      只有销售订单为：saleDeptId
      只有采购订单为：purDeptId
      其他为 deptId
        */

      if (['120101', '140313'].includes(this.billType)) {
        data.deptId = data.saleDeptId
      } else if (this.billType === '110101') {
        data.deptId = data.purDeptId
      }
      return data
    },
    deptIdFormProcess(data) {
      /*
      在采购管理-销售管理中
      只有销售订单为：saleDeptId
      只有采购订单为：purDeptId
      其他为 deptId
        */
      // 处理销售管理-部门id差异处理
      console.log("data.details 111", this.billType, data.details);
      if (['120101', '140313'].includes(this.billType)) {
        data.saleDeptId = data.deptId
      } else if (this.billType === '110101') {
        data.purDeptId = data.deptId
      } else if (this.billType === '140303') {
        data.details = data.details.map(x => ({
          ...x,
          // sourceBillType: x.billType
        }))
      }
      return data
    },
    openSelectGoods(bool) {
      if (!bool && storeSet.includes(this.TypeBill)) {
        //需要检验是否选了仓库时进入
        if (!this.form.storeId) {
          this.$message({
            message: '请先选择仓库',
            type: 'warning'
          })
          return
        }
      }
      if (storeSet.includes(this.TypeBill)) {
        this.selectGoodsExtraParams = {
          storeId: this.form.storeId
          // isEnableSale: this.isEnableSale
        }
      } else {
        this.selectGoodsExtraParams = {
          partnerId: this.form.partnerId
          // isEnableSale: this.isEnableSale
        }
      }

      this.$nextTick(() => {
        this.OpenGoods = true
        this.selectGoods = '选择商品'
      })
    },
    getTableList(newVal) {
      this.keepStatus = true
      this.changeBillDetailType(newVal)
      this.TypeBill = newVal
      // 销售出库单明细
      if (newVal === '120101' || newVal === '140313' || newVal === '120102' || newVal === '120103' || newVal === '140307' || newVal === '140312') {
        this.isEnablePurchase = null
        this.isEnableSale = true
      }
      // 采购
      if (newVal === '110101' || newVal === '110102' || newVal === '103' || newVal === '140303' ) {
        this.isEnablePurchase = true
        this.isEnableSale = null
      }
    },
    allFun(row, key, index) {
      if (row === '') {
        this.watchScroll()
      }
      // this.dataFormat(row, key, index)
      if (['110101', '110102', '110103'].includes(this.billType) && ['unitPrice', 'unitTaxPrice'].includes(key)) {
        this.$set(this.form.details[index], 'purPrice', row[key])
        if (this.form.details[index].units) {
          let unitIndex = this.form.details[index].units.findIndex(item => item.unitId == this.form.details[index].unitId)
          this.$set(this.form.details[index].units[unitIndex], 'unitPrice', row[key])
          this.$set(this.form.details[index].units[unitIndex], 'purPrice', row[key])
        }
      }
      if (['120101', '120102', '120103'].includes(this.billType) && ['unitPrice', 'unitTaxPrice'].includes(key)) {
        this.$set(this.form.details[index], 'salePrice', row[key])
        if (this.form.details[index].units) {
          let unitIndex = this.form.details[index].units.findIndex(item => item.unitId == this.form.details[index].unitId)
          this.$set(this.form.details[index].units[unitIndex], 'unitPrice', row[key])
          this.$set(this.form.details[index].units[unitIndex], 'salePrice', row[key])
        }
      }

      this.handleEdit(row, key, index)
    },
    dataFormat (row, key, index) {
      // let value = Number(row[key]).toFixed(3)
      // value = typeof Number(value) === 'number' && !isNaN(Number(value)) ? Number(value).toFixed(3) : undefined
      let value = Number(row[key]).toFixed(8)
      value = typeof Number(value) === 'number' && !isNaN(Number(value)) ? Number(value).toFixed(8) : undefined
      let val = Number(value)
      this.$set(this.form.details[index], key, val)
      // this.$set(this.form.details[index], key, value)
    },
    dataReCalc(index, key, value) {
      let formatValue = typeof Number(value) === 'number' && !isNaN(Number(value)) ? Number(value).toFixed(3) : undefined
      this.$set(this.form.details[index], key, formatValue)
    },
    dataFunReCalc(index, key, value) {
      let formatValue = typeof Number(value) === 'number' && !isNaN(Number(value)) ? Number(value).toFixed(8) : undefined
      let val = Number(formatValue)

      if (['110101', '110102', '110103'].includes(this.billType) && ['unitPrice', 'unitTaxPrice'].includes(key)) {
        this.$set(this.form.details[index], 'purPrice', val)
        if (this.form.details[index].units) {
          let unitIndex = this.form.details[index].units.findIndex(item => item.unitId == this.form.details[index].unitId)
          this.$set(this.form.details[index].units[unitIndex], 'unitPrice', val)
          this.$set(this.form.details[index].units[unitIndex], 'purPrice', val)
        }
      }
      if (['120101', '120102', '120103'].includes(this.billType) && ['unitPrice', 'unitTaxPrice'].includes(key)) {
        this.$set(this.form.details[index], 'salePrice', val)
        if (this.form.details[index].units) {
          let unitIndex = this.form.details[index].units.findIndex(item => item.unitId == this.form.details[index].unitId)
          this.$set(this.form.details[index].units[unitIndex], 'unitPrice', val)
          this.$set(this.form.details[index].units[unitIndex], 'salePrice', val)
        }
      }
      this.$set(this.form.details[index], key, val)
    },
    keyup(name, event, index, bool) {
      if (!bool && this.TypeBill === '107') {
        //需要检验是否选了仓库时进入
        if (!this.form.storeId) {
          this.$message({
            message: '请先选择仓库',
            type: 'warning'
          })
          return
        }
      }
      //判断是否输入编码或条码
      if (this.form.details[`${index}`][`${name}`]) {
        listGoods({
          [name]: this.form.details[`${index}`][`${name}`],
          partnerId: this.form.partnerId,
          isEnableSale: this.isEnableSale,
          isEnablePurchase: this.isEnablePurchase,
          searchType: this.searchType,
          queryCode: this.form.details[`${index}`][`${name}`]
        }).then(async response => {
          // 判断是否精准匹配 匹配直接赋值 不匹配弹出商品框
          if (response.total === 1) {
            event.target.blur()
            //使用vue的$set方法更新数组数据,使视图可编辑
            response.rows[0].unitPrice = response.rows[0].salePrice
            response.rows.forEach(item => {
              item.remark = null
            })
            this.$set(this.form.details, index, response.rows[0])
            this.iskey = !this.iskey
            this.form.details = this.form.details.filter(
              item =>
                (!(JSON.stringify(item) === '{}') &&
                  !(item.goodsId === undefined)) ||
                !(item.goodsId === undefined)
            )
            if (!bool && this.TypeBill === '107') {
              //需要获取商品当前库存数进入
              getGoodsFinalQty({
                goodsIds: [response.rows[0].goodsId],
                storeId: this.form.storeId
              }).then(response => {
                //账面数
                this.$set(
                  this.form.details[index],
                  'finalQty',
                  response.data.goodsQtyList[0].finalQty
                )
                //初始化基本单位数量
                this.$set(this.form.details[index], 'basUnitQty', 0)
              })
            }

            //字段转换并保留n位小数
            this.dataShowFilter()
          } else {
            this.queryCode = this.form.details[`${index}`][`${name}`]
            this.OpenGoods = true
          }
        })
      } else {
        //没输入直接弹出商品信息框
        event.target.blur()
        this.OpenGoods = true
      }
    }
  }
}

const masterLayoutPublic = {
  data() {
    return {}
  },
  methods: {}
}

export { searchItem, detailLayoutPublic, masterLayoutPublic }
