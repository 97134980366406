import Vue from 'vue'
import 'vxe-table/lib/index.css'
import {
  // 全局对象
  VXETable,

  // 功能模块
  // Filter,
  // Menu,
  // Edit,
  // Export,
  // Keyboard,
  Validator,
  // Footer,
  // 可选组件
  Icon,
  Column,
  Colgroup,
  // Grid,
  Toolbar,
  // Pager,
  Checkbox,
  // CheckboxGroup,
  // Radio,
  // RadioGroup,
  // RadioButton,
  // Input,
  // Textarea,
  // Button,
  // Modal,
  // Tooltip,
  // Form,
  // FormItem,
  // FormGather,
  // Select,
  // Optgroup,
  // Option,
  // Switch,
  // List,
  // Pulldown,

  // 表格
  Table,
  Footer
} from 'vxe-table'

// 表格功能
// Vue.use(Filter)
// .use(Edit)
// .use(Menu)
// .use(Export)
// .use(Keyboard)
// .use(Validator)

// 可选组件
Vue.use(Icon)
  .use(Column)
  .use(Colgroup)
  // .use(Grid)
  .use(Toolbar)
  // .use(Footer)
  // .use(Pager)
  .use(Checkbox)
  // .use(CheckboxGroup)
  // .use(Radio)
  // .use(RadioGroup)
  // .use(RadioButton)
  // .use(Input)
  // .use(Textarea)
  // .use(Button)
  // .use(Modal)
  // .use(Tooltip)
  // .use(Form)
  // .use(FormItem)
  // .use(FormGather)
  // .use(Select)
  // .use(Optgroup)
  // .use(Option)
  // .use(Switch)
  // .use(List)
  // .use(Pulldown)

  // 安装表格
  .use(Table)
  .use(Footer)
  .use(Validator)
